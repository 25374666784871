import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareOIDCHeaders as prepareHeaders } from './../auth';
import { API_URL } from '../config';
import { ITenantList, IUserProfile } from './types/UserTypes';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, prepareHeaders }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query<IUserProfile, void>({
      query: () => ({ url: `/users`, method: 'GET' }),
      providesTags: ['User'],
    }),
    getBookingCentre: builder.query<ITenantList[], void>({
      query: () => ({ url: `/tenants`, method: 'GET' }),
    }),
    saveBookingCentre: builder.mutation<Response, string>({
      query: (tenantKey) => ({
        url: `/users/${tenantKey}`,
        method: 'PUT',
      }),
      // Update the booking center upon successful submit
      async onQueryStarted(tenantKey, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUser', undefined, (draft) => {
              draft.bookingCentre = tenantKey;
            })
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetBookingCentreQuery,
  useSaveBookingCentreMutation,
} = userApi;
