import React, { SyntheticEvent } from 'react';
import {
  ErrorNotificationBar,
  SuccessNotificationBar,
} from './StyledNotificationBar';
import useNotifier from './use-notifier';

/**
 * Component make use of use Notifier hook to show the snack bar message
 * @returns
 */
export default function Notification() {
  const { message, removeMessage, severity } = useNotifier();

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    removeMessage();
  };
  const isSuccess = (msg: string) => {
    if (msg === 'success') {
      return true;
    } else {
      return false;
    }
  };

  return isSuccess(severity) ? (
    <SuccessNotificationBar
      handleClose={handleClose}
      message={message}
      severity={severity}
    />
  ) : (
    <ErrorNotificationBar
      handleClose={handleClose}
      message={message}
      severity={severity}
    />
  );
}
