import React, { ErrorInfo, ReactNode } from 'react';
import { Box, Typography, Button } from '@mui/material';

import { palette } from '../../theme/palette';

type ErrorBoundaryHandlerProps = {
  hasError: boolean;
  setHasError: (flag: boolean) => void;
  navigateBack: () => void;
  children: ReactNode;
};

type ErrorBoundaryHandlerStates = {
  hasError: boolean;
};

class ErrorBoundaryHandler extends React.Component<
  ErrorBoundaryHandlerProps,
  ErrorBoundaryHandlerStates
> {
  constructor(props: ErrorBoundaryHandlerProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: boolean) {
    return { hasError: true };
  }

  componentDidCatch(_error: Error, _errorInfo: ErrorInfo) {
    this.props.setHasError(true);
  }

  render() {
    return this.state.hasError ? (
      <Box
        data-testid={'errorboundary'}
        sx={{
          minHeight: 'calc(100vh - 4rem)',
          color: palette.text.primary,
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="calc(100vh - 3rem)"
          justifyContent={'center'}
          paddingTop="10px"
        >
          <Typography variant="h2">Oops... Something Went Wrong.</Typography>
          <Box
            onClick={() => {
              // eslint-disable-next-line react/no-set-state
              this.setState({ hasError: false });
              this.props.navigateBack();
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
            >
              Back To Home
            </Button>
          </Box>
        </Box>
      </Box>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundaryHandler;
