import React, { SyntheticEvent } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { Color } from './NotifierProvider';

interface Props {
  message: string;
  severity: Color;
  handleClose: (event: SyntheticEvent | Event, reason?: string) => void;
}

const CustomNotificationBar: React.FC<Props> = ({
  message,
  severity,
  handleClose,
  ...rest
}) => {
  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const msgTitle = capitalizeFirstLetter(severity);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      data-testid="snackbar"
      onClose={handleClose}
      open={!!message}
      sx={{ height: '100px', top: '150px !important' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        {...rest}
        data-testid="event-detail-alert"
      >
        <AlertTitle>{msgTitle}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default React.memo(CustomNotificationBar);
