import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useUserProfile from './hooks/useUserProfile';
import {
  useGetBookingCentreQuery,
  useSaveBookingCentreMutation,
} from '../../store/usersApi';
import { useTranslation } from 'react-i18next';
import { palette } from '../../theme/palette';
import ActionButton from '../../components/Button/ActionButton';

const BookingCentreSelection = ({
  onOpen,
  open,
}: {
  onOpen: (open: boolean) => void;
  open: boolean;
}) => {
  const radioName = 'center';
  const { t } = useTranslation(['common']);

  const { data: tenantList } = useGetBookingCentreQuery();
  const [saveBookingCentre, { isLoading, isError }] =
    useSaveBookingCentreMutation();
  const { selectedBookingCentre } = useUserProfile();
  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      center: Yup.string().required(`${t('common:bookingCentreSelect')}`),
    });
  }, [t]);

  const navigate = useNavigate();

  /**
   * Submit the information to backend and update the context
   * @param {} formValues
   */
  const submit = async (formValues: { center: string }) => {
    // if same selection, just close the popup
    if (formValues.center === selectedBookingCentre) {
      closeBooking();
      return;
    }
    try {
      await saveBookingCentre(formValues.center).unwrap();
      closeBooking();
      // if there is any previous booking center, which means it is a change. tat time route to home page
      // so that will decide the default page
      if (selectedBookingCentre) {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const cancel = () => {
    closeBooking();
  };

  const closeBooking = () => {
    onOpen(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ center: selectedBookingCentre }}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, handleSubmit }) => (
        <Dialog
          PaperProps={{ sx: { borderRadius: 0 } }}
          data-testid="dialog-bookingCentre"
          fullWidth
          open={open}
        >
          <Box sx={{ p: 4 }}>
            <DialogTitle>
              <Box>
                <Typography
                  color={palette.secondary.main}
                  data-testid="dialog-title"
                  sx={{ fontWeight: 'bold' }}
                  variant="body2"
                >
                  {t('common:selectBookingCentre')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ py: 5 }}>
                <Form>
                  <Box>
                    {isError && (
                      <Typography
                        color="error"
                        data-testid="dialog-error-message"
                        gutterBottom
                      >
                        Please try again
                      </Typography>
                    )}
                  </Box>
                  <Grid container>
                    <Box pr={2}>
                      <FormLabel data-testid="dialog-label">
                        <Typography
                          fontWeight="normal"
                          variant="caption"
                        >
                          {t('common:bookingCentre')}:
                        </Typography>
                      </FormLabel>
                    </Box>
                    <Grid item>
                      <RadioGroup
                        name={radioName}
                        onChange={(event) => {
                          setFieldValue(radioName, event.currentTarget.value);
                        }}
                        value={values.center}
                      >
                        {tenantList?.map((center) => (
                          <FormControlLabel
                            control={
                              <Radio
                                data-testid="dialog-radio-item"
                                style={{ transform: 'scale(0.6)' }}
                              />
                            }
                            data-testid="dialog-radio-label"
                            key={center?.id}
                            label={
                              <Typography
                                fontWeight="normal"
                                variant="caption"
                              >
                                {`${t('common:bankJuliusBaer')}` +
                                  center?.tenantName}
                              </Typography>
                            }
                            sx={{ height: '25px' }}
                            value={center?.tenantKey}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText
                        data-testid="dialog-selection-error"
                        error
                      >
                        {errors?.center}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Form>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', px: 0 }}>
              {selectedBookingCentre && (
                <ActionButton
                  color="secondary"
                  data-testid="dialog-cancel"
                  disabled={isLoading}
                  onClick={() => {
                    cancel();
                  }}
                  sx={{
                    mb: 0,
                    width: {
                      xs: '180px',
                      lg: '225px',
                    },
                  }}
                  variant="outlined"
                >
                  {t('common:cancel')}
                </ActionButton>
              )}
              <ActionButton
                color="secondary"
                data-testid="dialog-submit"
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  !isLoading && handleSubmit();
                }}
                sx={{
                  mb: 0,
                  width: {
                    xs: '180px',
                    lg: '225px',
                  },
                }}
              >
                {t('common:confirm')}
              </ActionButton>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </Formik>
  );
};

export default React.memo(BookingCentreSelection);
