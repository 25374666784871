import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Filter {
  field: string;
  selection: Array<string>;
}

export interface EntitlementState {
  filters: Array<Filter>;
}

export const initialState: EntitlementState = {
  filters: [],
};

export const entitlementFilter = createSlice({
  name: 'entitlement/filter',
  initialState,
  reducers: {
    addFilter: (
      state,
      { payload: { field, selection } }: PayloadAction<Filter>
    ) => {
      const filter = state.filters.find(
        (filterItem) => filterItem.field === field
      );
      if (!filter) {
        state.filters.push({
          field: field,
          selection: selection,
        });
      } else {
        filter.selection = selection;
      }
    },
    resetFilter: () => initialState,
  },
});

export const { addFilter, resetFilter } = entitlementFilter.actions;

export const entitlementFilterReducer = entitlementFilter.reducer;
