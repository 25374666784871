import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
/**
 * Not authorized screen
 * @returns
 */
const NotAuthorized = () => {
  const { t } = useTranslation(['common']);
  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        <Grid
          item
          lg={8}
          xs={12}
        >
          <Box sx={{ paddingTop: 4, textAlign: 'center' }}>
            <Typography
              data-testid="authorize-deny"
              variant="subtitle1"
            >
              {t('common:restrictedPageErrorMessage')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(NotAuthorized);
