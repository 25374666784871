import { ACCESS_ROLES, ACCESS_TYPES } from './access';
import { FUNCTIONS } from './function';

// Map of Screen to access Type for the role
const CA_ADMIN_ACCESS_MAP = new Map([
  [FUNCTIONS.USER.LANDING_PAGE, ACCESS_TYPES.WRITE],
  [FUNCTIONS.CONFIG.EVENT_TYPE, ACCESS_TYPES.WRITE],
  [FUNCTIONS.CONFIG.OPTION, ACCESS_TYPES.WRITE],
  [FUNCTIONS.CONFIG.SYSTEM, ACCESS_TYPES.NOACCESS],
  [FUNCTIONS.CONFIG.DASHBOARD, ACCESS_TYPES.READ],
]);

const CA_USER_ACCESS_MAP = new Map([
  [FUNCTIONS.USER.LANDING_PAGE, ACCESS_TYPES.WRITE],
  [FUNCTIONS.CONFIG.EVENT_TYPE, ACCESS_TYPES.NOACCESS],
  [FUNCTIONS.CONFIG.OPTION, ACCESS_TYPES.NOACCESS],
  [FUNCTIONS.CONFIG.SYSTEM, ACCESS_TYPES.NOACCESS],
  [FUNCTIONS.CONFIG.DASHBOARD, ACCESS_TYPES.NOACCESS],
]);

const SUPPORT_ADMIN_ACCESS_MAP = new Map([
  [FUNCTIONS.USER.LANDING_PAGE, ACCESS_TYPES.READ],
  [FUNCTIONS.CONFIG.EVENT_TYPE, ACCESS_TYPES.READ],
  [FUNCTIONS.CONFIG.OPTION, ACCESS_TYPES.READ],
  [FUNCTIONS.CONFIG.SYSTEM, ACCESS_TYPES.WRITE],
  [FUNCTIONS.CONFIG.DASHBOARD, ACCESS_TYPES.READ],
]);
// Later this can be come from the backend layer itself
// Map of Role to Screen Map
// Priority defined as below -> There is no priority defined by BA. So we defined our own
// Here There is no possibility that support admin will be mixed with other roles. So we dont have any issues is defining this below priority
// CA ADMIN AS 1
// SUPPORT ADMIN AS 2
// CA USER AS 3
export const ACCESS_MAP = new Map([
  [ACCESS_ROLES.CA_ADMIN, CA_ADMIN_ACCESS_MAP],
  [ACCESS_ROLES.SUPPORT_ADMIN, SUPPORT_ADMIN_ACCESS_MAP],
  [ACCESS_ROLES.CA_USER, CA_USER_ACCESS_MAP],
]);
