import { jbColors } from './jbColors';

export const palette = {
  background: {
    default: '#FFFFFF',
  },
  primary: {
    light: jbColors.royalBlue[40],
    dark: jbColors.royalBlue[100],
    main: jbColors.royalBlue[100],
    contrastText: jbColors.white,
  },
  secondary: {
    light: jbColors.reflexBlue[40],
    dark: jbColors.reflexBlue[100],
    main: jbColors.reflexBlue[100],
    contrastText: jbColors.white,
  },
  tertiary: {
    light: jbColors.mulberryPurple[40],
    dark: jbColors.mulberryPurple[100],
    main: jbColors.mulberryPurple[100],
    contrastText: jbColors.white,
  },
  error: {
    light: jbColors.carmineRed[40],
    dark: jbColors.carmineRed[100],
    main: jbColors.carmineRed[100],
    contrastText: jbColors.white,
  },
  warning: {
    light: jbColors.amberYellow[40],
    dark: jbColors.amberYellow[100],
    main: jbColors.amberYellow[90],
    contrastText: jbColors.white,
  },
  info: {
    light: jbColors.persianBlue[40],
    dark: jbColors.persianBlue[100],
    main: jbColors.persianBlue[90],
    contrastText: jbColors.white,
  },
  success: {
    light: jbColors.forestGreen[40],
    dark: jbColors.forestGreen[100],
    main: jbColors.forestGreen[100],
    contrastText: jbColors.white,
  },
  text: {
    primary: jbColors.black[100],
    secondary: jbColors.black[80],
    disabled: jbColors.black[50],
  },
  divider: jbColors.black[20],
  action: {
    selected: jbColors.forestGreen[20],
    hover: jbColors.royalBlue[10],
    disabledBackground: jbColors.black[20],
    disabled: jbColors.black[30],
  },
};
