import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AdminConfigValues {
  id?: number;
  description: string;
  code: string;
}

export type AdminConfigState = {
  adminConfigValues: AdminConfigValues;
  show: boolean;
};

export const initialState: AdminConfigState = {
  adminConfigValues: {
    code: '',
    description: '',
    id: undefined,
  },
  show: false,
};

export const adminConfig = createSlice({
  name: 'adminConfig',
  initialState,
  reducers: {
    setAdminConfig: (state, action: PayloadAction<AdminConfigState>) => {
      Object.assign(state, action.payload);
    },
    showAdminConfig: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { setAdminConfig, showAdminConfig } = adminConfig.actions;

export const adminConfigReducer = adminConfig.reducer;
