export const jbColors = {
  // Core palette
  white: '#FFFFFF',
  offWhite: '#FCFCFCFC',
  reflexBlue: {
    100: '#001489',
    90: '#1A2C95',
    80: '#3343A1',
    60: '#6672B8',
    40: '#99A1D0',
    20: '#CCD0E7',
    10: '#E6E8F3',
    5: '#F2F3F9',
  },
  stone: {
    100: '#B0AA7E',
    90: '#B8B28B',
    80: '#C0BB98',
    60: '#D0CCB2',
    40: '#DFDDCB',
    20: '#EFEEE5',
    10: '#F3F0E0',
  },
  black: {
    100: '#000000',
    70: '#4C4C4C',
    80: '#333333',
    50: '#808080',
    60: '#666666',
    40: '#999999',
    30: '#B2B2B2',
    20: '#CCCCCC',
    10: '#E5E5E5',
  },
  neutralGrey: {
    100: '#191919',
  },
  // Support palette
  royalBlue: {
    100: '#141E55',
    90: '#2C3566',
    80: '#434B77',
    60: '#727899',
    40: '#A1A5BB',
    20: '#D0D2DD',
    10: '#E8E9EE',
  },
  greenSmoke: {
    100: '#717C7D',
    80: '#8D9697',
    60: '#AAB0B1',
    40: '#C6CBCB',
    30: '#D4D8D8',
    20: '#E3E5E5',
    5: '#F8F8F9',
  },
  violetGrey: {
    100: '#7B6469',
    80: '#958387',
    60: '#B0A2A5',
    40: '#CAC1C3',
    20: '#E5E0E1',
  },

  alertBlue: {
    90: '#1A76FF',
  },
  // Technical palette
  persianBlue: {
    100: '#0075A3',
    90: '#198CAC',
    80: '#3399B5',
    60: '#66B2C8',
    40: '#99CCDA',
    20: '#CCE5ED',
    10: '#E5F2F6'
  },
  mulberryPurple: {
    100: '#6E2B62',
    90: '#7C4072',
    80: '#8B5581',
    60: '#A880A1',
    40: '#C5AAC0',
    20: '#E2D5E0',
  },
  forestGreen: {
    100: '#008675',
    90: '#199283',
    80: '#339E91',
    60: '#66B6AC',
    40: '#99CFC8',
    20: '#CCE7E3',
    10: '#E5F3F1',
  },
  carmineRed: {
    100: '#971B2F',
    90: '#A13244',
    80: '#AC4949',
    60: '#C17682',
    40: '#D5A4AC',
    20: '#EAD1D5',
  },
  amberYellow: {
    100: '#CC8A00',
    90: '#D19619',
    80: '#D6A133',
    60: '#E0B966',
    40: '#EBD099',
    20: '#F5E8CC',
  },
};

export const BACKGROUND_COLOR = '#F6F6F6'; // As it is not part of jb colors, it is defined here
