import React, { memo, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundaryHandler from './ErrorBoundaryHandler';

function ErrorBoundary({ children }: { children: ReactNode }) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key, hasError]);
  return (
    <ErrorBoundaryHandler
      hasError={hasError}
      navigateBack={() => navigate('/')}
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryHandler>
  );
}

export default memo(ErrorBoundary);
