import { DateRange } from '@mui/x-date-pickers-pro';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export interface FilterValues {
  diaryId: string;
  eventName: string;
  isin: string;
  securityNo: string;
  currency: string;
  additionalForm: string;
  eventType: string;
  eventNotificationStatus: string;
  eventNotificationType: string;
  extDeadlineDate: DateRange<dayjs.Dayjs>;
  bjbDeadlineDate: DateRange<dayjs.Dayjs>;
  payDate: DateRange<dayjs.Dayjs>;
  creationDate: DateRange<dayjs.Dayjs>;
  lastModifiedDate: DateRange<dayjs.Dayjs>;
  archived: boolean;
}

export type FilterState = {
  filterValues: FilterValues;
};

export const initialState: FilterState = {
  filterValues: {
    archived: false,
    diaryId: '',
    eventName: '',
    isin: '',
    securityNo: '',
    currency: '',
    additionalForm: '',
    eventType: '',
    eventNotificationStatus: '',
    eventNotificationType: '',
    extDeadlineDate: [null, null],
    bjbDeadlineDate: [null, null],
    payDate: [null, null],
    creationDate: [null, null],
    lastModifiedDate: [null, null],
  },
};

export const filter = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FilterState>) => {
      state.filterValues = action.payload.filterValues;
    },
    resetFilter: () => initialState,
  },
});

export const { setFilter, resetFilter } = filter.actions;

export const filterReducer = filter.reducer;
