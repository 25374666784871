import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps, CircularProgressProps } from '@mui/material';
import ActionButtonLoader from './ActionButtonLoader';

const ActionButtonStyled = styled(Button)<ButtonProps>(() => ({
  position: 'absolute',
}));

interface IActionButtonProps extends ButtonProps {
  loading?: boolean;
  loaderProps?: CircularProgressProps;
}
export default function ActionButton({
  sx,
  loading,
  loaderProps,
  children,
  ...rest
}: IActionButtonProps) {
  return (
    <ActionButtonStyled
      sx={{
        mb: {
          xs: 0.5,
          md: 0,
        },
        ml: {
          xs: 0,
          md: 2.5,
        },
        position: 'relative',
        ...sx,
      }}
      variant="contained"
      {...rest}
    >
      {children}
      {loading && <ActionButtonLoader
        {...loaderProps}
        data-testid="loading"
                  />}
    </ActionButtonStyled>
  );
}
