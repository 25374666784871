import { Box, BoxProps } from '@mui/material';
import React from 'react';

/**
 * Main container that will set the gutter responsively,
 * 120 Left and Right gutter for Large and Above, 32 bottom and top for all
 *
 * Max HD resolution only.. After that the width will be restricted to HD... Same applies for zoom out case also
 */
const ResponsiveContainer = ({ sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{ px: {
        xs: 2,
        md: 8,
        lg: 15
      },
      py: 4,
      maxWidth: '1920px', margin: '0 auto', ...sx }}
      {...rest}
    />
  );
};

export default React.memo(ResponsiveContainer);
