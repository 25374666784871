import {
  Configuration,
  ProtocolMode,
  PublicClientApplication,
} from '@azure/msal-browser';
import { OIDC_AUTHORITY, OIDC_CLIENTID } from '../config';

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: OIDC_CLIENTID as string,
    authority: OIDC_AUTHORITY,
    knownAuthorities: [OIDC_AUTHORITY as string],
    redirectUri: window.location.origin,
    protocolMode: ProtocolMode.OIDC,
  },
};

export const msalInstance = new PublicClientApplication(configuration);

// NOTE: NOT A GOOD WAY TO ACCESS THE BOOKING CENTER. BUT ADDED DUE TO TIME CONSTRAINTS FOR ANALYSIS
let BOOKING_CENTER: string;
export const setBookingCentre = (bookingCentre: string) => {
  BOOKING_CENTER = bookingCentre;
};

/**
 *
 * Create the Headers for the api
 */
export const prepareOIDCHeaders = async (headers: Headers) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts?.length >= 0) {
    const { accessToken, idToken } =
      await msalInstance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['openid'],
      });

    headers.set('Authorization', `Bearer ${accessToken}`);
    headers.set('X-IdToken', idToken);
  }

  if (BOOKING_CENTER) {
    headers.set('Target-Entity-Id', BOOKING_CENTER);
  }
  return headers;
};
