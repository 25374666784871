import packageJson from './../../package.json';
declare global {
  interface Window {
    REACT_APP_TARGET_ENV?: string;
    REACT_APP_API_URL?: string;
    REACT_APP_OIDC_PROVIDER?: string;
    REACT_APP_OIDC_CLIENTID?: string;
  }
}

export const TARGET_ENV =
  window.REACT_APP_TARGET_ENV ||
  process.env.REACT_APP_TARGET_ENV ||
  'production';

const BACKEND_URL = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

export const API_URL = `${BACKEND_URL}/api/v1`;
export const OIDC_AUTHORITY =
  window.REACT_APP_OIDC_PROVIDER || process.env.REACT_APP_OIDC_PROVIDER;
export const OIDC_CLIENTID =
  window.REACT_APP_OIDC_CLIENTID || process.env.REACT_APP_OIDC_CLIENTID;
export const OIDC_SCOPE = 'openid email profile';

// Event Details configuration
export const APPENDIX_LIMIT = parseInt(
  process.env.REACT_APP_APPENDIX_CHARACTER_LIMIT || '0'
);

export const BASENAME = '/';
if (process.env.NODE_ENV === 'production') {
  console.info(`Target env: ${TARGET_ENV}`);
  console.info(`API URL: ${API_URL}`);
}
console.info(`Target env: ${TARGET_ENV}`);
console.info(`API URL: ${API_URL}`);
console.info(`Authority: ${OIDC_AUTHORITY}`);
console.info(`Client Id: ${OIDC_CLIENTID}`);
console.info(`Scope: ${OIDC_SCOPE}`);
console.info(`Version: ${packageJson.version}`);

export const MUIX_LICENSE =
  '01167954fc2587621351641c8c6fd9f0Tz01MTQ2NSxFPTE2OTU4MTI2MjQ1MjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const KILO_BYTE = 1024;
export const MEGA_BYTE = KILO_BYTE * KILO_BYTE;