import { Box, Paper } from '@mui/material';
import ResponsiveContainer from './../ResponsiveContainer';
import React, { ReactNode } from 'react';

interface MainLayoutProps {
  header: ReactNode;
  children: ReactNode;
}
/**
 * Main layout which will load the content with the header, children
 */
const MainLayout = ({ header, children, ...rest }: MainLayoutProps) => {
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'background.default',
      }}
    >
      {header && (
        <Box
          sx={(theme) => ({
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          {header}
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        <Paper sx={{ height: '100% ' }}>
          <ResponsiveContainer
            sx={{
              height: '100%',
            }}
          >
            {children}
          </ResponsiveContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default React.memo(MainLayout);
