import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Filter {
  field: string;
  text: string;
  selection?: string[];
}

export interface AdminFilterState {
  events: Array<Filter>;
  options: Array<Filter>;
  configs: Array<Filter>;
}

export const initialState: AdminFilterState = {
  events: [],
  options: [],
  configs: [],
};

export const adminFilter = createSlice({
  name: 'adminFilter',
  initialState,
  reducers: {
    addEventFilter: (
      state,
      { payload: { field, text } }: PayloadAction<Filter>
    ) => {
      const filter = state.events.find(
        (filterItem) => filterItem.field === field
      );
      if (!filter) {
        state.events.push({
          field: field,
          text: text,
        });
      } else {
        filter.text = text;
      }
    },
    addOptionFilter: (
      state,
      { payload: { field, text, selection } }: PayloadAction<Filter>
    ) => {
      const filter = state.options.find(
        (filterItem) => filterItem.field === field
      );
      if (!filter) {
        state.options.push({
          field,
          text,
          selection,
        });
      } else {
        filter.text = text;
        filter.selection = selection;
      }
    },
    addConfigFilter: (
      state,
      { payload: { field, text, selection } }: PayloadAction<Filter>
    ) => {
      const filter = state.configs.find(
        (filterItem) => filterItem.field === field
      );
      if (!filter) {
        state.configs.push({
          field,
          text,
          selection,
        });
      } else {
        filter.text = text;
        filter.selection = selection;
      }
    },
    resetFilter: () => initialState,
  },
});

export const { addEventFilter, addOptionFilter, addConfigFilter, resetFilter } =
  adminFilter.actions;

export const adminFilterReducer = adminFilter.reducer;
