import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AdminEventValues {
  id?: number;
  description: string;
  code: string;
  altDescription?: string;
}

export type AdminEventState = {
  adminEventValues: AdminEventValues;
  show: boolean;
};

export const initialState: AdminEventState = {
  adminEventValues: {
    code: '',
    description: '',
    id: undefined,
    altDescription: '',
  },
  show: false,
};

export const adminEvent = createSlice({
  name: 'adminEvent',
  initialState,
  reducers: {
    setAdminEvent: (state, action: PayloadAction<AdminEventState>) => {
      Object.assign(state, action.payload);
    },
    showAdminEvent: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { setAdminEvent, showAdminEvent } = adminEvent.actions;

export const adminEventReducer = adminEvent.reducer;
