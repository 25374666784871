import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
/**
 * ApplicationRestricted screen
 * @returns
 */
const ApplicationRestricted = () => {
  const { t } = useTranslation(['common']);
  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          lg={8}
          xs={12}
        >
          <Box sx={{ pt: 12, textAlign: 'center' }}>
            <Typography variant="subtitle1">
              {t('common:profileRestricted')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(ApplicationRestricted);
