import i18n from './../../i18n/config';
export const entitlementStatusPayload = {
  references: [
    {
      code: i18n.t('entitlement:pendingElection'),
      optStatus: 'PENDING',
    },
    {
      code: i18n.t('entitlement:serviceTerminated'),
      serviceTerminated: true,
    },
    {
      code: i18n.t('entitlement:mepcMet'),
      criteriaMet: true,
    },
    {
      code: i18n.t('entitlement:manualExclusion'),
      manuallyExcluded: true,
    },
    {
      code: i18n.t('entitlement:manualInclusion'),
      manuallyIncluded: true,
    },
    {
      code: i18n.t('entitlement:pendingApproval'),
      recordStatusCode: 'RS-PENDING.APPR',
    },
  ],
};
