import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Loader } from '../components/Loader';
import AccessFunction from '../core/User/UserAccess/AccessFunction';
import {
  NAV_ENTITLEMENT,
  NAV_EVENT,
  NAV_CONFIG,
  NAV_DASHBOARD,
  NAV_INDIVIDUAL_ENTITLEMENT,
  NAV_EVENT_DETAIL,
} from './../common/constant';
import { ErrorBoundary } from 'core/ErrorBoundary';
import { FUNCTIONS } from 'core/User/UserAccess/';

const Event = React.lazy(() => import('../apps/Event'));
const EventDetail = React.lazy(() => import('../apps/EventDetail'));
const Components = React.lazy(() => import('../apps/components'));
const Dashboard = React.lazy(() => import('../apps/Dashboard'));
const Entitlement = React.lazy(() => import('../apps/EntitlementDetail'));
const IndividualEntitlement = React.lazy(
  () => import('../apps/IndividualEntitlement')
);
const AdminDashboard = React.lazy(() => import('../apps/AdminDashboard'));

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  return (
    <ErrorBoundary>
      <Box>
        <Routes>
          <Route
            element={
              <Suspense fallback={<Loader />}>
                <Components />
              </Suspense>
            }
            path="/components"
          />
          <Route
            element={
              <Suspense fallback={<Loader />}>
                <AccessFunction accessId={FUNCTIONS.USER.LANDING_PAGE}>
                  <Event />
                </AccessFunction>
              </Suspense>
            }
            path={`${NAV_EVENT}`}
          >
            <Route
              element={
                <Suspense fallback={<Loader />}>
                  <AccessFunction accessId={FUNCTIONS.USER.LANDING_PAGE}>
                    <EventDetail />
                  </AccessFunction>
                </Suspense>
              }
              path={`${NAV_EVENT_DETAIL}/:id`}
            />
            <Route
              element={
                <Suspense fallback={<Loader />}>
                  <AccessFunction accessId={FUNCTIONS.USER.LANDING_PAGE}>
                    <Entitlement />
                  </AccessFunction>
                </Suspense>
              }
              path={`${NAV_ENTITLEMENT}/:id`}
            >
              <Route
                element={
                  <Suspense fallback={<Loader />}>
                    <AccessFunction accessId={FUNCTIONS.USER.LANDING_PAGE}>
                      <IndividualEntitlement />
                    </AccessFunction>
                  </Suspense>
                }
                path={`${NAV_INDIVIDUAL_ENTITLEMENT}/:individualId`}
              />
            </Route>
          </Route>
          <Route
            element={
              <Suspense fallback={<Loader />}>
                <AccessFunction accessId={FUNCTIONS.USER.LANDING_PAGE}>
                  <Dashboard />
                </AccessFunction>
              </Suspense>
            }
            path={`/${NAV_DASHBOARD}`}
          />

          <Route
            element={
              <Suspense fallback={<Loader />}>
                <AccessFunction accessId={FUNCTIONS.CONFIG.DASHBOARD}>
                  <AdminDashboard />
                </AccessFunction>
              </Suspense>
            }
            path={`/${NAV_CONFIG}/*`}
          />
          <Route
            element={<Navigate to={`/${NAV_DASHBOARD}`} />}
            path="*"
          />
        </Routes>
      </Box>
    </ErrorBoundary>
  );
};

export default AppRoutes;
