import { createTheme } from '@mui/material';
import { jbColors } from './jbColors';
import MuiButton from './components/MuiButton';
import { palette } from './palette';
import { rem, typography } from './typography';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // PLACEHOLDER FOR BUTTON VARIANT OVERRIDES, LIKE BJB ANIMATED DASH
    dashed: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hint: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'hint' },
          style: {
            fontSize: rem(10),
            fontFamily: 'Verlag',
          },
        },
      ],
    },
    MuiButton: MuiButton,
    MuiAppBar: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiMenu: {
      defaultProps: {
        autoFocus: false,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: rem(14),
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.light,
        },
        colorSecondary: {
          color: palette.secondary.light,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: rem(12),
          fontWeight: 300,
          borderBottom: `none`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
          '&.MuiTableRow-hover:hover': {
            backgroundColor: palette.action.selected,
          },
        },
        head: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            color: jbColors.black[80],
            borderBottom: `1px solid ${palette.primary.light}`,
            padding: 0,
            paddingBottom: 11,
            fontSize: rem(12),
            fontWeight: 300,
            lineHeight: '1.5rem',
            paddingRight: 10,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: jbColors.black[80],
          fontFamily: 'Verlag',
          fontSize: rem(12),
          fontWeight: 'normal',
        },
        displayedRows: {
          color: jbColors.black[80],
          fontFamily: 'Verlag',
          fontSize: rem(12),
          fontWeight: 'normal',
        },
        input: {
          color: jbColors.black[80],
          fontSize: rem(12),
          fontFamily: 'Verlag',
          fontWeight: 'normal',
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined', disabled: true, multiline: true },
          style: {
            backgroundColor: jbColors.black[10],
            borderRadius: '10px',
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: '300',
          fontSize: rem(16),
          color: jbColors.black[100],
        },
        multiline: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          padding: '24px',
          borderRadius: '10px',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      variants: [
        {
          props: { variant: 'filled', severity: 'success' },
          style: {
            color: '#36B933',
            borderLeft: '5px solid #36B933',
          },
        },
        {
          props: { variant: 'filled', severity: 'error' },
          style: {
            color: '#EE251A',
            borderLeft: '5px solid #EE251A',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: rem(12),
          backgroundColor: `${jbColors.white}`,
          borderRadius: 0,
          height: '118px',
          width: '580px',
          alignItems: 'normal',
          boxShadow: `0px 5px 10px 0px ${jbColors.black[20]}`,
        },
        action: {
          display: 'flex',
          alignItems: 'flex-start',
          paddingX: 12,
          color: `${jbColors.black[100]}`,
        },
        message: {
          fontSize: `${rem(14)}`,
          fontWeight: 'normal',
          paddingTop: '10px',
          color: `${jbColors.black[80]}`,
        },
        icon: {
          paddingTop: '12px',
          marginLeft: '20px',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: rem(12),
          borderRadius: 0,
          textTransform: 'uppercase',
          borderColor: palette.primary.light,
          height: 26,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 325,
          maxHeight: 106,
          fontSize: rem(16),
          fontWeight: 300,
          color: jbColors.black[100],
          backgroundColor: jbColors.offWhite,
          boxShadow: `0px 5px 10px 0px ${jbColors.black[20]}`,
        },
        arrow: {
          color: jbColors.white,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: rem(12),
          alignItems: 'center',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          letterSpacing: '0.5px',
          color: palette.primary.main,
          borderBottom: `1px solid ${palette.primary.light}`,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 0,
          paddingBottom: 16,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
