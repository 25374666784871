/**
 * For the navigation/routing constant name start with NAV_
 * @start NAV_
 **/
export const NAV_EVENT = 'event';
export const NAV_EVENT_DETAIL = 'detail';
export const NAV_INDIVIDUAL_ENTITLEMENT = 'individual-entitlement';
export const NAV_ENTITLEMENT = 'entitlement';
export const NAV_CONFIG = 'config';
export const NAV_DASHBOARD = 'dashboard';
