import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareOIDCHeaders as prepareHeaders } from '../auth';
import { API_URL } from '../config';
import { IReferenceData } from './types/EventTypes';

export const referenceApi = createApi({
  reducerPath: 'referenceApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, prepareHeaders }),
  tagTypes: ['Reference'],
  endpoints: (builder) => ({
    getReferences: builder.query<IReferenceData, void>({
      query: () => ({ url: `/references`, method: 'GET' }),
    }),
  }),
});

export const { useGetReferencesQuery } = referenceApi;
