// List of functions
export const FUNCTIONS = {
  CONFIG: {
    EVENT_TYPE: 'EVENT_TYPE',
    OPTION: 'OPTION',
    SYSTEM: 'SYSTEM',
    DASHBOARD: 'DASHBOARD',
  },
  USER: {
    // This is function id for all the user related functionalities. The UI is not handling the WRITE access for this unlike the Admin.
    // It is completely handled by BE
    LANDING_PAGE: 'LANDING_PAGE',
  },
};
