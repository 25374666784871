import { EnumLiteralsOf } from "core/types";

export type UserRole = EnumLiteralsOf<typeof ACCESS_ROLES>;

export const ACCESS_ROLES = Object.freeze({
  CA_ADMIN: 'ca_admin' as const,
  CA_USER: 'ca_user' as const,
  SUPPORT_ADMIN: 'support_admin' as const
});

// List of access types
// NOTE: NO WRITE ACCESS IMPLEMENTATION FOR NOW, AS THERE IS NO REQUIREMENT
export const ACCESS_TYPES = {
  NOACCESS: 0, // Not able to view
  READ: 1, // Read Access
  WRITE: 2, // Read Access
};
