import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AdminOptionValues {
  id?: number;
  description: string;
  code: string;
}

export type AdminOptionState = {
  adminOptionValues: AdminOptionValues;
  show: boolean;
};

export const initialState: AdminOptionState = {
  adminOptionValues: {
    code: '',
    description: '',
    id: undefined,
  },
  show: false,
};

export const adminOption = createSlice({
  name: 'adminOption',
  initialState,
  reducers: {
    setAdminOption: (state, action: PayloadAction<AdminOptionState>) => {
      Object.assign(state, action.payload);
    },
    showAdminOption: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { setAdminOption, showAdminOption } = adminOption.actions;

export const adminOptionReducer = adminOption.reducer;
