import {
  AppBar,
  Box,
  Divider,
  Grid,
  Typography,
  BoxProps,
  Toolbar,
} from '@mui/material';
import ResponsiveContainer from '../../ResponsiveContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserProfile from '../../../core/User/UserProfile';
import { useIsAuthenticated } from '@azure/msal-react';

/**
 * Header Component to show the header of the application based on the Main Layout
 */
const Header = (props: BoxProps) => {
  const { t } = useTranslation(['common']);
  const isAuthenticated = useIsAuthenticated();
  return (
    <AppBar
      color="secondary"
      elevation={2}
      position="sticky"
      sx={{ zIndex: 99 }}
    >
      <Toolbar disableGutters>
        <ResponsiveContainer
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 0,
          }}
        >
          <Box sx={{ py: 2.25 }}>
            <Link
              data-testid="header-logo-link"
              style={{ textDecoration: 'none' }}
              to={'/'}
            >
              <Grid
                alignItems="center"
                container
                item
              >
                <img
                  alt={t('common:app')}
                  data-testid="header-logo"
                  height="28"
                  src={`/images/logo.png`}
                  width="125"
                />
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{
                    height: '28px',
                    mx: 2,
                  }}
                  variant="middle"
                />
                <Typography
                  color="secondary.contrastText"
                  variant="subtitle2"
                >
                  {t('common:app')}
                </Typography>
              </Grid>
            </Link>
          </Box>
          <Box sx={{ alignSelf: 'center' }}>
            {isAuthenticated && <UserProfile />}
          </Box>
        </ResponsiveContainer>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
