import { useMemo } from 'react';
import { ACCESS_MAP, ACCESS_TYPES } from '../UserAccess';
import useUserProfile from './useUserProfile';

/**
 * Generates a custom hook for user access.
 *
 * @return {Object} An object containing the `hasReadAccess` function.
 */
const useUserAccess = () => {
  const { userProfile } = useUserProfile();

  const accessMap = useMemo(() => {
    if (!userProfile?.roles || userProfile.roles.length === 0) {
      return null;
    }
    // This will the user roles in priority order
    const accessUsers = Array.from(ACCESS_MAP.keys());
    const userRoleByPriority = accessUsers.find((role) =>
      userProfile?.roles.includes(role)
    );
    return userRoleByPriority && ACCESS_MAP.has(userRoleByPriority)
      ? ACCESS_MAP.get(userRoleByPriority)
      : null;
  }, [userProfile?.roles]);
  /**
   * Checks if the user has read access to the given function.
   *
   * @param {string} functionId - The ID of the function to check access for.
   * @return {boolean} Returns true if the user has read access, false otherwise.
   */
  const hasReadAccess = (functionId: string) => {
    if (accessMap) {
      const accessType = accessMap.get(functionId);
      if (accessType && accessType >= ACCESS_TYPES.READ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Checks if the user has write access to the given function.
   *
   * @param {string} functionId - The ID of the function to check access for.
   * @return {boolean} Returns true if the user has write access, false otherwise.
   */
  const hasWriteAccess = (functionId: string) => {
    if (accessMap) {
      const accessType = accessMap.get(functionId);
      if (accessType && accessType >= ACCESS_TYPES.WRITE) {
        return true;
      }
    }
    return false;
  };

  return {
    hasReadAccess,
    hasWriteAccess
  };
};

export default useUserAccess;
