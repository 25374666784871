import { useMemo } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useGetUserQuery } from '../../../store/usersApi';
import { API_UNAUTHORIZED } from 'common/constant';

const useUserProfile = () => {
  const isAuthenticated = useIsAuthenticated();

  //To Get User Profile RTK query
  interface ErrorState {
    status: number;
    data: object;
  }
  const {
    data: userProfile,
    isLoading: loading,
    isError,
    isSuccess,
    error,
  } = useGetUserQuery(undefined, { skip: !isAuthenticated });
  //To Get user Initials
  const initials = useMemo(() => {
    if (!userProfile) {
      return '';
    }
    const firstInitial =
      userProfile && userProfile.firstName && userProfile.firstName.charAt(0);
    const lastInitial =
      userProfile && userProfile.lastName && userProfile.lastName.charAt(0);
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }, [userProfile]);

  const selectedBookingCentre = useMemo(
    () => (userProfile && userProfile?.bookingCentre) ?? '',
    [userProfile]
  );

  return {
    isSuccess,
    userProfile,
    error: isError,
    unAuthorized: (error &&
      (error as ErrorState).status === API_UNAUTHORIZED) as boolean,
    loading,
    initials,
    selectedBookingCentre,
  };
};

export default useUserProfile;
