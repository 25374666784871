/* eslint-disable react/display-name */
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Provider } from 'react-redux';
import store from './store';
import { NotifierProvider } from './core/Notifier';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from './auth';

/**
 * Higher order function to wrap the application with redux store and other providers
 * @param {*} Component
 * @returns
 */
const withAppProviders =
  (Component: React.ComponentType) => (props?: object) => {
    return () => (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <NotifierProvider>
              <CssBaseline />
              <Component {...props} />
            </NotifierProvider>
          </ThemeProvider>
        </Provider>
      </MsalProvider>
    );
  };

export default withAppProviders;
