import React, { useEffect } from 'react';
import './i18n/config';
import { Box } from '@mui/material';
import withAppProviders from './withAppProviders';
import AppRoutes from './layouts/AppRoutes';
import MainLayout from './layouts/Main/MainLayout';
import { Header } from './layouts/Main/TopBar';
import { BrowserRouter } from 'react-router-dom';
import { BASENAME, MUIX_LICENSE } from './config';
import useUserProfile from './core/User/hooks/useUserProfile';
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setBookingCentre } from 'auth';
import ApplicationRestricted from 'core/User/ApplicationRestricted';
// Must be called before first render.
LicenseInfo.setLicenseKey(MUIX_LICENSE);

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const { selectedBookingCentre, unAuthorized } = useUserProfile();
  // Setting the booking center in the in memory, so we can make using for interception
  useEffect(() => {
    selectedBookingCentre && setBookingCentre(selectedBookingCentre);
  }, [selectedBookingCentre]);

  return (
    <Box data-testid="app">
      <BrowserRouter basename={BASENAME}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MainLayout header={<Header data-testid="app-header" />}>
            <AuthenticatedTemplate>
              {selectedBookingCentre && <AppRoutes />}
              {unAuthorized && <ApplicationRestricted />}
            </AuthenticatedTemplate>
          </MainLayout>
        </LocalizationProvider>
      </BrowserRouter>
    </Box>
  );
};

export default withAppProviders(App)();
