import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareOIDCHeaders as prepareHeaders } from '../auth';
import { API_URL } from '../config';
import {
  IAdminDashboardEvent,
  IAdminEventListPayload,
  IAdminRequest,
  IAdminOptionEvent,
  IAdminOptionListPayload,
  IAdminConfigEvent,
  IAdminConfigListPayload,
} from './types/AdminTypes';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, prepareHeaders }),
  tagTypes: ['AdminEvent', 'AdminOption', 'AdminConfig'],
  endpoints: (builder) => ({
    createUpdateAdmin: builder.mutation<Response, IAdminRequest>({
      query: (request) => ({
        url: `/admin/update`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['AdminEvent', 'AdminOption', 'AdminConfig'],
    }),
    getAdminEvents: builder.query<IAdminDashboardEvent, IAdminEventListPayload>(
      {
        query: (request) => {
          const { page, size, ...postData } = request;
          return {
            url: `/admin/eventType?page=${page}&size=${size}`,
            method: 'POST',
            body: postData,
          };
        },
        providesTags: ['AdminEvent'],
        keepUnusedDataFor: 0,
      }
    ),
    getAdminOptions: builder.query<IAdminOptionEvent, IAdminOptionListPayload>({
      query: (request) => {
        const { page, size, ...postData } = request;
        return {
          url: `/admin/option?page=${page}&size=${size}`,
          method: 'POST',
          body: postData,
        };
      },
      providesTags: ['AdminOption'],
      keepUnusedDataFor: 0,
    }),
    getAdminConfig: builder.query<IAdminConfigEvent, IAdminConfigListPayload>({
      query: (request) => {
        const { page, size, ...postData } = request;
        return {
          url: `/admin/config?page=${page}&size=${size}`,
          method: 'POST',
          body: postData,
        };
      },
      providesTags: ['AdminConfig'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateUpdateAdminMutation,
  useGetAdminEventsQuery,
  useGetAdminOptionsQuery,
  useGetAdminConfigQuery,
} = adminApi;
