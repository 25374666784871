import { EnumLiteralsOf } from './helperTypes';

export const EventDetailType = Object.freeze({
  FILTEREDVALUE: 9999 as const,
});

export type EventDeadLineColor = EnumLiteralsOf<typeof EventDeadLineColorTypes>;

export const EventDeadLineColorTypes = Object.freeze({
  ORANGE: 'ORANGE' as const,
  RED: 'RED' as const,
});

export type EventDeadLineIcon = EnumLiteralsOf<typeof EventDeadLineIconTypes>;

export const EventDeadLineIconTypes = Object.freeze({
  WARNING: 'WARNING' as const,
});