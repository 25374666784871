import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Popover,
  Skeleton,
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useUserProfile from './hooks/useUserProfile';
import BookingCentreSelection from './BookingCentreSelection';
import { BookingCentreTypes } from '../types';
import { useGetBookingCentreQuery } from './../../store/usersApi';
import { useNotifier } from './../Notifier';
import { useNavigate } from 'react-router-dom';
import AccessFunction from './UserAccess/AccessFunction';
import { FUNCTIONS } from './UserAccess';

const UserProfile = () => {
  const { t } = useTranslation(['common']);
  const { userProfile, isSuccess, initials, loading, error, unAuthorized } =
    useUserProfile();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openBookingCentre, setOpenBookingCentre] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  useGetBookingCentreQuery();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = () => {
    handleClose();
    setOpenBookingCentre(true);
  };

  const navigateToAdmin = () => {
    handleClose();
    navigate('/config');
  };

  const bookingCentreDesc = useMemo((): string => {
    if (!userProfile) {
      return '';
    }

    if (userProfile.bookingCentre === BookingCentreTypes.HK) {
      return t('common:hongKong');
    } else if (userProfile.bookingCentre === BookingCentreTypes.SG) {
      return t('common:singapore');
    } else {
      return '';
    }
  }, [t, userProfile]);

  const { showErrorMessage } = useNotifier();
  useEffect(() => {
    if (
      isSuccess &&
      (!userProfile?.bookingCentre || userProfile?.bookingCentre === '')
    ) {
      setOpenBookingCentre(true);
    }
  }, [isSuccess, userProfile?.bookingCentre]);

  useEffect(() => {
    //show the error message only if it's not unauthorize access
    error && !unAuthorized && showErrorMessage();
  }, [error, showErrorMessage, unAuthorized]);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="inherit"
        data-testid="header-username-button"
        disabled={loading || error}
        onClick={handleClick}
        sx={{ p: 0 }}
      >
        <Avatar
          data-testid="header-username"
          sx={{ fontWeight: 'bold' }}
        >
          {loading ? (
            <Skeleton
              animation="wave"
              data-testid="userProfile-loading"
              height={40}
              variant="circular"
              width={40}
            />
          ) : (
            initials
          )}
        </Avatar>
      </IconButton>
      <Popover
        PaperProps={{ style: { borderRadius: 0 } }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="userProfile-info"
        onClose={handleClose}
        open={open}
        sx={{ top: 20 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid sx={{ px: 3.4, py: 2.8 }}>
          <Typography
            data-testid="userProfile-name"
            fontWeight="normal"
            variant="caption"
          >
            {t('common:userProfile')}
            {':'} {userProfile?.firstName}
            {userProfile?.firstName && userProfile?.lastName && ' '}
            {userProfile?.lastName}
            {userProfile?.firstName &&
              userProfile?.lastName &&
              userProfile?.userId &&
              ', '}
            {userProfile?.userId}
          </Typography>
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sx={{ px: 3.4, pb: 3 }}
        >
          <Box>
            <Typography
              data-testid="userProfile-BookingCentre"
              fontWeight="normal"
              variant="caption"
            >
              {t('common:bookingCentre')}
              {': '}
              {bookingCentreDesc}
            </Typography>
          </Box>
          <Box sx={{ pl: 2 }}>
            <Link
              component="button"
              data-testid="userProfile-BookingCentre-Link"
              fontWeight="normal"
              onClick={handleChange}
              variant="caption"
            >
              {t('common:switch')}
            </Link>
          </Box>
        </Grid>
        <AccessFunction
          accessId={FUNCTIONS.CONFIG.DASHBOARD}
          children={(access: { read: boolean }) =>
            access.read ? (
              <Grid
                alignItems="center"
                container
                item
                justifyContent="space-between"
                sx={{ px: 3.4, pb: 3 }}
              >
                <Box>
                  <Link
                    component="button"
                    data-testid="admin-dashboard-navigation"
                    fontWeight="normal"
                    onClick={navigateToAdmin}
                    variant="caption"
                  >
                    {t('common:navigation.systemDashboard')}
                  </Link>
                </Box>
              </Grid>
            ) : null
          }
        />
      </Popover>
      {openBookingCentre && (
        <BookingCentreSelection
          onOpen={setOpenBookingCentre}
          open={openBookingCentre}
        />
      )}
    </>
  );
};

export default React.memo(UserProfile);
