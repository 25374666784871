/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit';
import { entitlementApi } from './entitlementApi';
import { eventApi } from './eventApi';
import { userApi } from './usersApi';
import { referenceApi } from './referenceApi';
import { filterReducer } from './filter/eventFilterSlice';
import { entitlementFilterReducer } from './entitlement/entitlementFilterSlice';
import { adminEventReducer } from './admin/adminEventSlice';
import { adminOptionReducer } from './admin/adminOptionSlice';
import { adminApi } from './adminApi';
import { adminFilterReducer } from './adminFilter/adminFilterSlice';
import { adminConfigReducer } from './admin/adminConfigSlice';

export const ROOT_ACTIONS = {
  logout: 'logout',
};

// Define the Reducers that will always be present in the application
const staticReducers = {
  [userApi.reducerPath]: userApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [entitlementApi.reducerPath]: entitlementApi.reducer,
  [referenceApi.reducerPath]: referenceApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
};

const createReducer = (asyncReducers?: any) => (state: any, action: any) => {
  // combine the static reducers
  const combinedReducer = combineReducers({
    filter: filterReducer,
    entitlement: entitlementFilterReducer,
    adminEvent: adminEventReducer,
    adminOption: adminOptionReducer,
    adminFilter: adminFilterReducer,
    adminConfig: adminConfigReducer,
    ...staticReducers,
  });

  return combinedReducer
    ? combinedReducer(state, action as never)
    : { ...state };
};

createReducer();

export default createReducer;
