import React, { ReactNode, useCallback } from 'react';
import NotAuthorized from '../NotAuthorized';
import useUserAccess from '../hooks/useUserAccess';

/**
 * Renders the content based on the user's access permissions.
 *
 * @param {string} accessId - The ID of the access.
 * @param {ReactNode | Function} children - The content to be rendered.
 * @return {ReactElement} The rendered content.
 */
const AccessFunction = ({
  accessId,
  children,
}: {
  accessId: string;
  children: ReactNode |
  (({ read, write }: { read: boolean; write: boolean }) => void);
}) => {
  const { hasReadAccess, hasWriteAccess } = useUserAccess();
  const read = hasReadAccess(accessId);
  const write = hasWriteAccess(accessId);

  const toRenderContent = useCallback(() => {
    // If the children is function, give the control to child
    if (typeof children === 'function') {
      return children({ read, write });
    }
    // Show the children (React node) if read access
    if (read) {
      return children;
    }
    return <NotAuthorized />;
  }, [read, write, children]);

  return <>{toRenderContent()}</>;
};

export default AccessFunction;
