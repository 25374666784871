import { AlertProps, styled } from '@mui/material';
import CustomNotificationBar from './CustomNotificationBar';
import { rem } from './../../theme/typography';

export const SuccessNotificationBar = styled(CustomNotificationBar)<AlertProps>(
  ({ theme }) => ({
    '& .MuiAlertTitle-root': {
      color: '#36B933',
      fontWeight: '400',
      fontSize: rem(20),
    },
    '& .MuiAlert-icon': {
      color: '#36B933',
    },
  })
);

export const ErrorNotificationBar = styled(CustomNotificationBar)<AlertProps>(
  ({ theme }) => ({
    '& .MuiAlertTitle-root': {
      color: '#EE251A',
      fontWeight: '400',
      fontSize: rem(20),
    },
    '& .MuiAlert-icon': {
      color: '#EE251A',
    },
  })
);
