import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';
import enEvent from './en/event.json';
import dashboard from './en/dashboard.json';
import enEntitlement from './en/entitlement.json';
import adminEvent from './en/admin.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: enCommon,
    event: enEvent,
    dashboard: dashboard,
    entitlement: enEntitlement,
    admin:adminEvent,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['common', 'event', 'entitlement', 'dashboard', 'admin'],
  defaultNS,
  resources,
});

export default i18n;
