import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareOIDCHeaders as prepareHeaders } from '../auth';
import { API_URL } from '../config';
import { entitlementStatusPayload } from './config/entitlementPayload';
import {
  IEntitlementList,
  IEntitlementListPayload,
  IEntitlementStatusPayload,
  IEntitlementOptions,
  IEntitlementStatus,
  IIndividualEntitlementDetail,
  IIndividualEntitlementRequest,
} from './types/EntitlementTypes';
import { INDIVIDUAL_ENTITLEMENT_UPDATE } from './constants';

export const entitlementApi = createApi({
  reducerPath: 'entitlementApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, prepareHeaders }),
  tagTypes: ['Entitlement', 'Entitlements', 'IndividualEntitlement'],
  endpoints: (builder) => ({
    getEntitlementStatus: builder.query<
      IEntitlementStatus,
      IEntitlementStatusPayload
    >({
      query: (request) => {
        const reqPayload = { ...request, ...entitlementStatusPayload };
        return {
          url: '/statistics/entitlements',
          method: 'POST',
          body: reqPayload,
        };
      },
      providesTags: ['Entitlements'],
      keepUnusedDataFor: 0,
    }),

    getEntitlements: builder.query<IEntitlementList, IEntitlementListPayload>({
      query: (request) => {
        const { page, size, ...postData } = request;
        return {
          url: `/entitlements?page=${page}&size=${size}`,
          method: 'POST',
          body: postData,
        };
      },
      providesTags: ['Entitlements'],
      keepUnusedDataFor: 0,
    }),

    getEntitlementOptions: builder.query<
      IEntitlementOptions,
      IEntitlementStatusPayload
    >({
      query: (request) => {
        const { diaryId, eventId } = request;
        return {
          url: `/entitlements/criteria/${diaryId}/${eventId}`,
          method: 'GET',
        };
      },
      providesTags: ['Entitlements'],
      keepUnusedDataFor: 0,
    }),

    getIndividualEntitlementData: builder.query<
      IIndividualEntitlementDetail,
      string
    >({
      query: (individualId) => ({
        url: `/entitlements/${individualId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['IndividualEntitlement'],
    }),

    editIndividualEntitlement: builder.mutation<
      Response,
      IIndividualEntitlementRequest
    >({
      query: (request) => ({
        url: `${INDIVIDUAL_ENTITLEMENT_UPDATE}`,
        method: 'PUT',
        body: { ...request, action: 'LOCK' },
      }),
      invalidatesTags: ['IndividualEntitlement'],
    }),

    cancelIndividualEntitlement: builder.mutation<
      Response,
      IIndividualEntitlementRequest
    >({
      query: (request) => ({
        url: `${INDIVIDUAL_ENTITLEMENT_UPDATE}`,
        method: 'PUT',
        body: { ...request, action: 'CANCEL' },
      }),
      invalidatesTags: ['IndividualEntitlement'],
    }),

    submitIndividualEntitlement: builder.mutation<
      Response,
      IIndividualEntitlementRequest
    >({
      query: (request) => ({
        url: `${INDIVIDUAL_ENTITLEMENT_UPDATE}`,
        method: 'PUT',
        body: { ...request, action: 'EDIT' },
      }),
      invalidatesTags: ['IndividualEntitlement', 'Entitlements'],
    }),

    approveIndividualEntitlement: builder.mutation<
      Response,
      IIndividualEntitlementRequest
    >({
      query: (request) => ({
        url: `${INDIVIDUAL_ENTITLEMENT_UPDATE}`,
        method: 'PUT',
        body: { ...request, action: 'APPROVE' },
      }),
      invalidatesTags: ['IndividualEntitlement', 'Entitlements'],
    }),

    rejectIndividualEntitlement: builder.mutation<
      Response,
      IIndividualEntitlementRequest
    >({
      query: (request) => ({
        url: `${INDIVIDUAL_ENTITLEMENT_UPDATE}`,
        method: 'PUT',
        body: { ...request, action: 'REJECT' },
      }),
      invalidatesTags: ['IndividualEntitlement', 'Entitlements'],
    }),
  }),
});

export const {
  useGetEntitlementStatusQuery,
  useGetEntitlementsQuery,
  useGetEntitlementOptionsQuery,
  useGetIndividualEntitlementDataQuery,
  useEditIndividualEntitlementMutation,
  useCancelIndividualEntitlementMutation,
  useSubmitIndividualEntitlementMutation,
  useApproveIndividualEntitlementMutation,
  useRejectIndividualEntitlementMutation,
} = entitlementApi;
