import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareOIDCHeaders as prepareHeaders } from '../auth';
import { API_URL } from '../config';
import {
  IDashboardEvent,
  IEventDetails,
  IEventListPayload,
  IEventRequest,
} from './types/EventTypes';

const getFormData = (eventPayload: IEventRequest) => {
  const formData = new FormData();
  if(eventPayload?.files && eventPayload?.files?.length > 0) {
    eventPayload?.files.forEach((file: File) => {
      formData.append('UploadFile', file);
    });
    delete eventPayload.files;
  }
  if(eventPayload?.deletedFiles && eventPayload?.deletedFiles?.length > 0) {
    formData.append(
        'DeleteFile',
        JSON.stringify(eventPayload?.deletedFiles)
      );
    delete eventPayload.deletedFiles;
  }
  formData.append(
    'Event',
    JSON.stringify(eventPayload)
  );
  return formData;
};

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, prepareHeaders }),
  tagTypes: ['Event', 'Events'],
  endpoints: (builder) => ({
    getEventData: builder.query<IEventDetails, string>({
      query: (id) => ({ url: `/events/${id}`, method: 'GET' }),
      keepUnusedDataFor: 0,
      providesTags: ['Event'],
    }),
    saveEvent: builder.mutation<Response, IEventRequest>({
      query: (request) => ({
        url: `/events/${request.id}/save`,
        method: 'PUT',
        body: getFormData(request),
        headers: {},
      }),
      // In case of error, invalidate only the list not the detail
      // This is for the case where the T24 update happened at the background, so the error should be shown but the new data should not be get updated here
      invalidatesTags: (result, error, arg) => error ? ['Events'] : ['Events', 'Event'],
    }),
    submitEvent: builder.mutation<Response, IEventRequest>({
      query: (request) => ({
        url: `/events/${request.id}/submit`,
        method: 'PUT',
        body: getFormData(request),
        headers: {},
      }),
      // In case of error, invalidate only the list not the detail
      // This is for the case where the T24 update happened at the background, so the error should be shown but the new data should not be get updated here
      invalidatesTags: (result, error, arg) => error ? ['Events'] : ['Events', 'Event'],
    }),
    deleteEvent: builder.mutation<Blob, string>({
      query: (id) => ({
        url: `/events/${id}/delete`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Events'],
    }),
    approveEvent: builder.mutation<Response, IEventRequest>({
      query: (request) => ({
        url: `/events/${request.id}/approve`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Events'],
    }),
    unlockEvent: builder.mutation<Response, string>({
      query: (id) => ({
        url: `/events/${id}/release`,
        method: 'PUT'
      }),
    }),
    rejectEvent: builder.mutation<Response, IEventRequest>({
      query: (request) => ({
        url: `/events/${request.id}/reject`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: ['Events'],
    }),
    getEvents: builder.query<IDashboardEvent, IEventListPayload>({
      query: (request) => {
        const { page, size, ...postData } = request;
        return {
          url: `/events?page=${page}&size=${size}`,
          method: 'POST',
          body: postData,
        };
      },
      providesTags: ['Events'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetEventDataQuery,
  useApproveEventMutation,
  useDeleteEventMutation,
  useRejectEventMutation,
  useSaveEventMutation,
  useSubmitEventMutation,
  useUnlockEventMutation,
  useGetEventsQuery,
} = eventApi;
