/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import createReducer from './rootReducer';
import { eventApi } from './eventApi';
import { userApi } from './usersApi';
import { entitlementApi } from './entitlementApi';
import { referenceApi } from './referenceApi';
import { adminApi } from './adminApi';

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares: Array<any> = [];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed: (_getState: any, _action: any, logEntry: any) => !logEntry.error,
  });

  middlewares.push(logger);
}
middlewares.push(userApi.middleware);
middlewares.push(eventApi.middleware);
middlewares.push(entitlementApi.middleware);
middlewares.push(referenceApi.middleware);
middlewares.push(adminApi.middleware);

//to dispatch store for testing
export const storeObject = {
  reducer: createReducer(),
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
};

const store: any = configureStore(storeObject);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<RootState, void, Action>;

export default store;
