import * as React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress, CircularProgressProps } from '@mui/material';

const ActionButtonLoaderStyled =
    styled(CircularProgress)<CircularProgressProps>(
  () => ({
    position: 'absolute',
    right: 4,
  })
);

export default function ActionButtonLoader({ ...rest }: CircularProgressProps) {
  return (
    <ActionButtonLoaderStyled
      color="inherit"
      size={15}
      {...rest}
    />
  );
}
