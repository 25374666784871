import React from 'react';
import { CircularProgress } from '@mui/material';

/**
 *
 * Loader to notify the user for wait to select dropdown values.
 * Specifically can use for dropdown IconComponent
 */
const LoaderForDropdown = () => (
  <CircularProgress
    data-testid="loader-for-dropdown"
    size={12}
  />
);

export default LoaderForDropdown;
