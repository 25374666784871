import { rem } from '../typography';
import { palette } from '../palette';
import { jbColors } from '../jbColors';
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
const sizeSmallPadding = `${rem(2)} ${rem(4)}`;
const sizeMediumPadding = `${rem(8)} ${rem(24)}`;
const sizeLargePadding = `${rem(10)} ${rem(26)}`;

const MuiButton: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      letterSpacing: 1,
      borderRadius: 0,
      padding: sizeMediumPadding,
    },
    containedPrimary: {
      border: `1px solid ${palette.primary.main}`,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.primary.dark}`,
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
        backgroundColor: palette.action.disabled,
        color: palette.secondary.contrastText,
      },
    },
    containedSecondary: {
      border: `1px solid ${palette.secondary.main}`,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.secondary.dark}`,
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
        backgroundColor: palette.action.disabled,
        color: palette.secondary.contrastText,
      },
    },
    containedSuccess: {
      border: `1px solid ${palette.success.main}`,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.success.dark}`,
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
        backgroundColor: palette.action.disabled,
        color: palette.success.contrastText,
      },
    },
    containedError: {
      border: `1px solid ${palette.error.main}`,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.error.dark}`,
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
        backgroundColor: palette.action.disabled,
        color: palette.error.contrastText,
      },
    },
    outlinedPrimary: {
      border: `1px solid ${palette.primary.main}`,
      color: palette.primary.main,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.primary.dark}`,
        color: `1px solid ${palette.primary.dark}`,
      },
      '&:disabled': {
        border: `1px solid ${palette.divider}`,
      },
    },
    outlinedSecondary: {
      border: `1px solid ${palette.secondary.main}`,
      color: palette.secondary.main,
      fontWeight: 'normal',
      '&:hover': {
        border: `1px solid ${palette.secondary.dark}`,
        color: palette.secondary.dark,
      },
      '&:disabled': {
        border: `1px solid ${palette.secondary.main}`,
      },
    },
    containedSizeLarge: {
      padding: sizeLargePadding,
      minWidth: rem(166),
      minHeight: rem(50),
    },
    containedSizeSmall: {
      padding: sizeSmallPadding,
      fontFamily: 'Verlag',
      fontSize: rem(12),
    },
    outlinedSizeLarge: {
      padding: sizeLargePadding,
      minWidth: rem(166),
      minHeight: rem(50),
    },
    outlinedSizeSmall: {
      padding: sizeSmallPadding,
      fontFamily: 'Verlag',
      fontSize: rem(12),
    },
  },
  variants: [
    {
      props: { variant: 'dashed' },
      style: {
        textTransform: 'none',
        border: `1px dashed ${jbColors.reflexBlue[100]}`,
      },
    },
    {
      props: { variant: 'dashed', color: 'secondary' },
      style: {
        border: `1px dashed ${jbColors.reflexBlue[100]}`,
      },
    },
  ],
};

export default MuiButton;
